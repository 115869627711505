import React, { useState, useEffect } from "react";
import axios from "axios";
import styles from "./AdminPanel.module.scss"; // Путь к файлу со стилями

const AdminPanel = ({ isAdmin }) => {
  const [accounts, setAccounts] = useState("");
  const [proxies, setProxies] = useState("");
  const [browsers, setBrowsers] = useState([]);

  useEffect(() => {
    if (!isAdmin) {
      // Редирект или отображение ошибки, если пользователь не администратор
      return;
    }

    // Получение аккаунтов из базы данных
    axios
      .get(`${process.env.REACT_APP_URL}/accounts`)
      .then((response) => {
        const arr = response.data.map((acc) => acc.account);
        setAccounts(arr.join("\n"));
      })
      .catch((error) => {
        console.error("Ошибка при получении аккаунтов:", error);
      });

    // Получение прокси из базы данных
    axios
      .get(`${process.env.REACT_APP_URL}/proxies`)
      .then((response) => {
        const arr = response.data.map((acc) => acc.proxy);

        setProxies(arr.join("\n"));
      })
      .catch((error) => {
        console.error("Ошибка при получении прокси:", error);
      });

    axios
      .get(`${process.env.REACT_APP_URL}/browsers`)
      .then((response) => {
        const arr = Object.keys(response.data);
        console.log(Object.keys(response.data));
        setBrowsers(arr);
      })
      .catch((error) => {
        console.error("Ошибка при получении браузеров:", error);
      });
  }, [isAdmin]);

  const handleAddAccounts = () => {
    const accountsArray = accounts.split("\n").filter(Boolean);

    // Отправка запроса на сервер для добавления аккаунтов
    axios
      .post(`${process.env.REACT_APP_URL}/accounts`, {
        accounts: accountsArray,
      })
      .then((response) => {
        console.log(response.data.msg);
        alert("Аккаунты успешно добавлены");
        setProxies(response.data.map((account) => account.account).join("\n"));
      })
      .catch((error) => {
        console.error("Ошибка при добавлении аккаунтов:", error);
      });
  };

  const handleAddProxies = () => {
    const proxiesArray = proxies.split("\n").filter(Boolean);

    // Отправка запроса на сервер для добавления прокси
    axios
      .post(`${process.env.REACT_APP_URL}/proxies`, { proxies: proxiesArray })
      .then((response) => {
        console.log(response.data.msg);
        alert("Прокси успешно добавлены");
        setProxies(response.data.map((proxy) => proxy.proxy).join("\n"));
      })
      .catch((error) => {
        console.error("Ошибка при добавлении прокси:", error);
      });
  };

  const handleGetScreenshot = (id) => {
    // Отправка запроса на сервер для получения скриншота
    axios
      .get(`${process.env.REACT_APP_URL}/screenshot/${id}`, {
        responseType: "arraybuffer",
      })
      .then((response) => {
        const imageBlob = new Blob([response.data], { type: "image/png" });
        const imageUrl = URL.createObjectURL(imageBlob);
        window.open(imageUrl); // Открыть скриншот в новой вкладке
      })
      .catch((error) => {
        console.error("Ошибка при получении скриншота:", error);
      });
  };

  const handleDeleteBrowser = (id) => {
    // Отправка запроса на сервер для получения скриншота
    axios
      .delete(`${process.env.REACT_APP_URL}/delete/${id}`)
      .then((response) => {
        const arr = Object.keys(response.data.data);
        console.log(arr);
        if (arr && arr.length) {
          setBrowsers((prev) => arr);
        } else {
          setBrowsers([]);
        }
      })
      .catch((error) => {
        console.error("Ошибка при удалении браузера", error);
      });
  };

  return (
    <div className={styles.adminPanel}>
      <div className={styles.block}>
        <h2>Добавление аккаунтов</h2>
        <textarea
          value={accounts}
          onChange={(e) => setAccounts(e.target.value)}
          placeholder="Введите аккаунты, каждый с новой строки"
        />
        <button onClick={handleAddAccounts}>Добавить аккаунты</button>
      </div>
      <div className={styles.block}>
        <h2>Добавление прокси</h2>
        <textarea
          value={proxies}
          onChange={(e) => setProxies(e.target.value)}
          placeholder="Введите прокси, каждый с новой строки"
        />
        <button onClick={handleAddProxies}>Добавить прокси</button>
      </div>
      <div className={styles.block}>
        <h2>Браузеры</h2>
        <ul>
          {browsers.map((browser, idx) => (
            <li className={styles.brsLi} key={browser}>
              Браузер {idx + 1}{" "}
              <div className={styles.brs}>
                <button onClick={() => handleDeleteBrowser(browser)}>
                  Удалить браузер
                </button>
                <button onClick={() => handleGetScreenshot(browser)}>
                  Получить скриншот
                </button>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default AdminPanel;
