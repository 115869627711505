import React, { useEffect, useState } from "react";
import LoginForm from "../../components/LoginForm/LoginForm";
import AdminPanel from "../../components/AdminPanel/AdminPanel";
import axios from "axios";

const AdminPage = () => {
  const [loggedIn, setLoggedIn] = useState(false);
  const [isAdmin, setIsAdmin] = useState(true);

  useEffect(() => {
    const token = localStorage.getItem("token");
    const checkToken = async (token) => {
      try {
        const { data } = await axios.get(`${process.env.REACT_APP_URL}/admin`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (data) {
          return true;
        }
      } catch (error) {
        return false;
      }
    };

    const isTokenValid = checkToken(token);

    if (!token || !isTokenValid) {
      setLoggedIn(false);
    }

    if (token && isTokenValid) {
      setLoggedIn(true);
    }
  });
  return (
    <>
      {!loggedIn && <LoginForm setLoggedIn={setLoggedIn} />}
      {loggedIn && <AdminPanel isAdmin={isAdmin} setIsAdmin={setIsAdmin} />}
    </>
  );
};

export default AdminPage;
