import { createSlice } from '@reduxjs/toolkit';
import { countries as countriesData } from '../../data/countries';

const initialState = {
    countries: countriesData,
    activeCountry: countriesData[0],
    searchQuery: '',
};

const countriesSlice = createSlice({
    name: 'countries',
    initialState,
    reducers: {
        setActiveCountry(state, action) {
            state.activeCountry = action.payload;
        },
        setSearchQuery(state, action) {
            state.searchQuery = action.payload;
        },
        filterCountries(state, action) {
            const { searchQuery } = action.payload;
            state.countries = countriesData.filter(country =>
                country.country.toLowerCase().includes(searchQuery.toLowerCase()) || country.phoneCode.toLowerCase().includes(searchQuery.toLowerCase())
            );
            if (searchQuery === '') {
                state.countries = countriesData; // Если строка поиска пуста, возвращаем все страны
            }
        },
    },
});

export const { setActiveCountry, setSearchQuery, filterCountries } = countriesSlice.actions;

export default countriesSlice.reducer;