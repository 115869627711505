import React, { useEffect } from "react";
import styles from "./LoadingScreen.module.scss";
import { ReactComponent as Loader } from "../../assets/loader.svg";
import classNames from "classnames";
import { ReactComponent as Tinder } from "../../assets/tinderpopup.svg";
import { ReactComponent as Cross } from "../../assets/cross.svg";
import { useDispatch, useSelector } from "react-redux";
import { clearAllLoading } from "../../features/popups/popupsSlice";

const LoadingScreen = () => {
  const dispatch = useDispatch();
  const popupsState = useSelector((state) => state.popups);

  // Проверяем, есть ли хотя бы один попап с состоянием isLoading === true
  const isLoading = Object.values(popupsState).some((popup) => popup.isLoading);

  const handlePopupClose = () => {
    dispatch(clearAllLoading()); // Отправляем действие для снятия состояния загрузки у всех попапов
  };

  return (
    <div className={classNames(styles.popup, { [styles.active]: isLoading })}>
      <div className={styles.popupInner}>
        <div>
          <Cross className={styles.cross} onClick={() => handlePopupClose()} />
          <Tinder />
        </div>
        <Loader className={styles.loader} />
      </div>
    </div>
  );
};

export default LoadingScreen;
