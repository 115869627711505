import React, { useState } from "react";
import styles from "./CountriesList.module.scss";
import { ReactComponent as Arrow } from "../../assets/arrowBack.svg";
import { ReactComponent as Search } from "../../assets/search.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  filterCountries,
  setActiveCountry,
  setSearchQuery,
} from "../../features/countries/countriesSlice";

const CountriesList = ({ visible }) => {
  const dispatch = useDispatch();
  const countries = useSelector((state) => state.countries.countries);
  const searchQuery = useSelector((state) => state.countries.searchQuery);

  const handleSearchInputChange = (event) => {
    const searchValue = event.target.value;
    dispatch(setSearchQuery(searchValue));
    dispatch(filterCountries({ searchQuery: searchValue }));
  };

  const handleCountryClick = (country) => {
    dispatch(setSearchQuery(""));
    dispatch(filterCountries({ searchQuery: "" }));
    dispatch(setActiveCountry(country));
    visible(false);
  };

  return (
    <div className={styles.wrap}>
      <div className={styles.top}>
        <Arrow className={styles.arrow} onClick={() => visible(false)} />
        <form className={styles.form}>
          <input
            value={searchQuery}
            onChange={(e) => handleSearchInputChange(e)}
            type="text"
            placeholder="Search"
          />
          <Search />
        </form>
      </div>
      <ul className={styles.list}>
        {countries && countries.length
          ? countries.map((country) => {
              return (
                <li
                  onClick={() => handleCountryClick(country)}
                  key={country.id}
                >
                  <h4>{country.country}</h4>
                  <p>{country.phoneCode}</p>
                </li>
              );
            })
          : ""}
      </ul>
    </div>
  );
};

export default CountriesList;
