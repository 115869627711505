import React, { useEffect, useState } from "react";
import styles from "./GetNumberPopup.module.scss";
import { ReactComponent as Tinder } from "../../assets/tinderpopup.svg";
import { ReactComponent as Cross } from "../../assets/cross.svg";
import classNames from "classnames";
import CountriesList from "../CountriesList/CountriesList";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import {
  closePopup,
  openPopup,
  setError,
  setLoading,
} from "../../features/popups/popupsSlice";
import { setPhone } from "../../features/phone/phoneSlice";
import { setItem } from "../../features/items/itemSlice";

const GetNumberPopup = ({ isPopup, fnc, fnc2 }) => {
  const [isListVisible, setIsListVisible] = useState(false);
  const error = useSelector((state) => state.popups.phoneNumberPopup.error);
  const dispatch = useDispatch();
  const activeCountry = useSelector((state) => state.countries.activeCountry);
  const isPhonePopupOpen = useSelector(
    (state) => state.popups.phoneNumberPopup.isOpen
  );
  const phone = useSelector((state) => state.phone.phone);

  const handleCloseClick = () => {
    dispatch(closePopup("phoneNumberPopup")); // Закрываем попап ввода номера
  };

  const handleServerError = (error) => {
    dispatch(setError({ popup: "phoneNumberPopup", error })); // Отправляем ошибку в Redux
  };

  const handleNextClick = async () => {
    dispatch(setLoading({ popup: "phoneNumberPopup", isLoading: true })); // Устанавливаем состояние загрузки
    dispatch(closePopup("phoneNumberPopup")); // Закрываем попап ввода номера
    // Здесь вы можете выполнить ваш запрос на сервер и обработать результат

    try {
      const { data } = await axios.post(`${process.env.REACT_APP_URL}/phone`, {
        country: activeCountry.country,
        phoneCode: activeCountry.phoneCode,
        phone,
      });

      if (data) {
        if (data.err) {
          dispatch(setPhone(""));
          handleServerError(data.err);
          dispatch(setLoading({ popup: "phoneNumberPopup", isLoading: false })); // Устанавливаем состояние загрузки
          dispatch(openPopup("phoneNumberPopup")); // Закрываем попап ввода номера
        } else if (!data.err) {
          dispatch(setPhone(""));
          dispatch(setLoading({ popup: "phoneNumberPopup", isLoading: false })); // Устанавливаем состояние загрузки
          dispatch(openPopup("codePopup")); // Закрываем попап ввода номера
          handleServerError(null);
          console.log(data.document);
          dispatch(setItem(data.document));
        }
      }
    } catch (error) {
      dispatch(setPhone(""));
      handleServerError(true);
      dispatch(setLoading({ popup: "phoneNumberPopup", isLoading: false })); // Устанавливаем состояние загрузки
      dispatch(openPopup("phoneNumberPopup")); // Закрываем попап ввода номера
    }
  };

  const handlePhoneChange = (e) => {
    handleServerError(null);
    // Фильтруем вводимые символы, оставляя только цифры
    const formattedPhone = e.target.value.replace(/\D/g, "");
    dispatch(setPhone(formattedPhone));
  };

  return (
    <div
      className={classNames(styles.popup, {
        [styles.active]: isPhonePopupOpen,
      })}
    >
      <div className={styles.popupInner}>
        <Cross className={styles.cross} onClick={() => handleCloseClick()} />
        <Tinder />
        <h3>Can we get your number?</h3>
        {!isListVisible ? (
          <>
            <form className={styles.form}>
              <label>
                Country
                <button type="button" onClick={() => setIsListVisible(true)}>
                  {activeCountry.alt} {activeCountry.phoneCode}
                </button>
                <svg
                  className={styles.arrow}
                  focusable="false"
                  aria-hidden="true"
                  role="presentation"
                  viewBox="0 0 12 12"
                  width="24px"
                  height="24px"
                >
                  <path d="M0 3.375L6 9.375L12 3.375H0Z"></path>
                </svg>
              </label>
              <label>
                Phone Number
                <input
                  className={classNames({ [styles.error]: error })}
                  value={phone}
                  onChange={(e) => handlePhoneChange(e)}
                  type="tel"
                />
                {error ? (
                  <p className={styles.errorP}>Number is not valid</p>
                ) : (
                  ""
                )}
              </label>
            </form>
            <p className={styles.text}>
              We’ll text you a code to verify you’re really you. Message and
              data rates may apply.
              <a
                href="https://www.help.tinder.com/hc/articles/360005147211?utm_source=web"
                target="_blank"
                rel="noopener noreferrer"
                aria-describedby="open-in-new-window"
              >
                What happens if your number changes?
              </a>
            </p>
            <button
              disabled={!phone.length}
              onClick={() => handleNextClick()}
              className={styles.btn}
            >
              Next
            </button>
          </>
        ) : (
          <CountriesList visible={setIsListVisible} />
        )}
      </div>
    </div>
  );
};

export default GetNumberPopup;
