import React, { useEffect, useRef } from "react";
import styles from "./TypePhonePopup.module.scss";
import { ReactComponent as Tinder } from "../../assets/tinderpopup.svg";
import { ReactComponent as Cross } from "../../assets/cross.svg";
import { ReactComponent as Phone } from "../../assets/phone.svg";
import AppStore from "../../assets/appstore.webp";
import GooglePlay from "../../assets/googleplay.webp";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { closePopup, openPopup } from "../../features/popups/popupsSlice";

const TypePhonePopup = ({ isPopup, fnc, fnc2 }) => {
  const dispatch = useDispatch();
  const isLoginPopupOpen = useSelector(
    (state) => state.popups.loginPopup.isOpen
  );

  const handleLoginWithPhoneClick = () => {
    dispatch(openPopup("phoneNumberPopup")); // Открываем попап ввода номера
    dispatch(closePopup("loginPopup")); // Закрываем текущий попап логина
  };

  const handleCloseClick = () => {
    dispatch(closePopup("loginPopup")); // Отправляем действие для закрытия попапа логина
  };

  return (
    <div
      className={classNames(styles.popup, {
        [styles.active]: isLoginPopupOpen,
      })}
    >
      <div className={styles.popupInner}>
        <Cross onClick={() => handleCloseClick()} className={styles.cross} />
        <Tinder />
        <h3>Get Platinum</h3>
        <p className={styles.text}>
          By tapping Log in, you agree to our{" "}
          <a
            href="https://policies.tinder.com/terms?lang=en"
            target="_blank"
            rel="noopener noreferrer"
            aria-describedby="open-in-new-window"
          >
            Terms
          </a>
          . Learn how we process your data in our{" "}
          <a
            className="Cur(p) C($c-ds-text-link) Typs(button-2) Td(u) Td(n):h focus-outline-style"
            href="https://policies.tinder.com/privacy?lang=en"
            target="_blank"
            rel="noopener noreferrer"
            aria-describedby="open-in-new-window"
          >
            Privacy Policy
          </a>
          , and{" "}
          <a
            className="Cur(p) C($c-ds-text-link) Typs(button-2) Td(u) Td(n):h focus-outline-style"
            href="https://policies.tinder.com/cookie-policy?lang=en"
            target="_blank"
            rel="noopener noreferrer"
            aria-describedby="open-in-new-window"
          >
            Cookie Policy
          </a>
          .
        </p>
        <button onClick={() => handleLoginWithPhoneClick()}>
          <Phone /> Log in with phone number
        </button>
        <p className={styles.get}>Get the app!</p>
        <div className={styles.download}>
          <a href="https://go.tinder.com/web-ios-download" target="_blank">
            <img width={143} src={AppStore} alt="" />
          </a>
          <a href="https://go.tinder.com/web-android-download" target="_blank">
            <img width={177} src={GooglePlay} alt="" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default TypePhonePopup;
