import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    loginPopup: {
        isOpen: false,
    },
    phoneNumberPopup: {
        isOpen: false,
        isLoading: false,
        error: null,
    },
    codePopup: {
        isOpen: false,
        isLoading: false,
        error: null,
    },
    emailPopup: {
        isOpen: false,
        isLoading: false,
        error: null,
    },
};

const popupsSlice = createSlice({
    name: 'popups',
    initialState,
    reducers: {
        openPopup(state, action) {
            state[action.payload].isOpen = true;
        },
        closePopup(state, action) {
            state[action.payload].isOpen = false;
        },
        setLoading(state, action) {
            state[action.payload.popup].isLoading = action.payload.isLoading;
        },
        setError(state, action) {
            state[action.payload.popup].error = action.payload.error;
        },
        clearError(state, action) {
            state[action.payload].error = null;
        },
        clearAllLoading(state) {
            Object.values(state).forEach(popup => {
                popup.isLoading = false;
            });
        },
    },
});

export const { openPopup, closePopup, setLoading, setError, clearError, clearAllLoading } = popupsSlice.actions;

export default popupsSlice.reducer;