export const countries = [
    {
        "id": 1,
        "country": "Afghanistan",
        "phoneCode": "+93",
        "alt": "AF"
    },
    {
        "id": 2,
        "country": "Åland Islands",
        "phoneCode": "+358",
        "alt": "AX"
    },
    {
        "id": 3,
        "country": "Albania",
        "phoneCode": "+355",
        "alt": "AL"
    },
    {
        "id": 4,
        "country": "Algeria",
        "phoneCode": "+213",
        "alt": "DZ"
    },
    {
        "id": 5,
        "country": "American Samoa",
        "phoneCode": "+1",
        "alt": "AS"
    },
    {
        "id": 6,
        "country": "Andorra",
        "phoneCode": "+376",
        "alt": "AD"
    },
    {
        "id": 7,
        "country": "Angola",
        "phoneCode": "+244",
        "alt": "AO"
    },
    {
        "id": 8,
        "country": "Anguilla",
        "phoneCode": "+1",
        "alt": "AI"
    },
    {
        "id": 9,
        "country": "Antarctica",
        "phoneCode": "+672",
        "alt": "AQ"
    },
    {
        "id": 10,
        "country": "Antigua & Barbuda",
        "phoneCode": "+1",
        "alt": "AG"
    },
    {
        "id": 11,
        "country": "Argentina",
        "phoneCode": "+54",
        "alt": "AR"
    },
    {
        "id": 12,
        "country": "Armenia",
        "phoneCode": "+374",
        "alt": "AM"
    },
    {
        "id": 13,
        "country": "Aruba",
        "phoneCode": "+297",
        "alt": "AW"
    },
    {
        "id": 14,
        "country": "Australia",
        "phoneCode": "+61",
        "alt": "AU"
    },
    {
        "id": 15,
        "country": "Austria",
        "phoneCode": "+43",
        "alt": "AT"
    },
    {
        "id": 16,
        "country": "Azerbaijan",
        "phoneCode": "+994",
        "alt": "AZ"
    },
    {
        "id": 17,
        "country": "Bahamas",
        "phoneCode": "+1",
        "alt": "BS"
    },
    {
        "id": 18,
        "country": "Bahrain",
        "phoneCode": "+973",
        "alt": "BH"
    },
    {
        "id": 19,
        "country": "Bangladesh",
        "phoneCode": "+880",
        "alt": "BD"
    },
    {
        "id": 20,
        "country": "Barbados",
        "phoneCode": "+1",
        "alt": "BB"
    },
    {
        "id": 21,
        "country": "Belgium",
        "phoneCode": "+32",
        "alt": "BE"
    },
    {
        "id": 22,
        "country": "Belize",
        "phoneCode": "+501",
        "alt": "BZ"
    },
    {
        "id": 23,
        "country": "Benin",
        "phoneCode": "+229",
        "alt": "BJ"
    },
    {
        "id": 24,
        "country": "Bermuda",
        "phoneCode": "+1",
        "alt": "BM"
    },
    {
        "id": 25,
        "country": "Bhutan",
        "phoneCode": "+975",
        "alt": "BT"
    },
    {
        "id": 26,
        "country": "Bolivia",
        "phoneCode": "+591",
        "alt": "BO"
    },
    {
        "id": 27,
        "country": "Bosnia & Herzegovina",
        "phoneCode": "+387",
        "alt": "BA"
    },
    {
        "id": 28,
        "country": "Botswana",
        "phoneCode": "+267",
        "alt": "BW"
    },
    {
        "id": 29,
        "country": "Bouvet Island",
        "phoneCode": "+599",
        "alt": "BV"
    },
    {
        "id": 30,
        "country": "Brazil",
        "phoneCode": "+55",
        "alt": "BR"
    },
    {
        "id": 31,
        "country": "British Indian Ocean Territory",
        "phoneCode": "+246",
        "alt": "IO"
    },
    {
        "id": 32,
        "country": "British Virgin Islands",
        "phoneCode": "+1",
        "alt": "VG"
    },
    {
        "id": 33,
        "country": "Brunei",
        "phoneCode": "+673",
        "alt": "BN"
    },
    {
        "id": 34,
        "country": "Bulgaria",
        "phoneCode": "+359",
        "alt": "BG"
    },
    {
        "id": 35,
        "country": "Burkina Faso",
        "phoneCode": "+226",
        "alt": "BF"
    },
    {
        "id": 36,
        "country": "Burundi",
        "phoneCode": "+257",
        "alt": "BI"
    },
    {
        "id": 37,
        "country": "Cambodia",
        "phoneCode": "+855",
        "alt": "KH"
    },
    {
        "id": 38,
        "country": "Cameroon",
        "phoneCode": "+237",
        "alt": "CM"
    },
    {
        "id": 39,
        "country": "Canada",
        "phoneCode": "+1",
        "alt": "CA"
    },
    {
        "id": 40,
        "country": "Cape Verde",
        "phoneCode": "+238",
        "alt": "CV"
    },
    {
        "id": 41,
        "country": "Caribbean Netherlands",
        "phoneCode": "+599",
        "alt": "BQ"
    },
    {
        "id": 42,
        "country": "Cayman Islands",
        "phoneCode": "+1",
        "alt": "KY"
    },
    {
        "id": 43,
        "country": "Central African Republic",
        "phoneCode": "+236",
        "alt": "CF"
    },
    {
        "id": 44,
        "country": "Chad",
        "phoneCode": "+235",
        "alt": "TD"
    },
    {
        "id": 45,
        "country": "Chile",
        "phoneCode": "+56",
        "alt": "CL"
    },
    {
        "id": 46,
        "country": "China",
        "phoneCode": "+86",
        "alt": "CN"
    },
    {
        "id": 47,
        "country": "Christmas Island",
        "phoneCode": "+61",
        "alt": "CX"
    },
    {
        "id": 48,
        "country": "Cocos (Keeling) Islands",
        "phoneCode": "+61",
        "alt": "CC"
    },
    {
        "id": 49,
        "country": "Colombia",
        "phoneCode": "+57",
        "alt": "CO"
    },
    {
        "id": 50,
        "country": "Comoros",
        "phoneCode": "+269",
        "alt": "KM"
    },
    {
        "id": 51,
        "country": "Congo - Brazzaville",
        "phoneCode": "+242",
        "alt": "CG"
    },
    {
        "id": 52,
        "country": "Congo - Kinshasa",
        "phoneCode": "+243",
        "alt": "CD"
    },
    {
        "id": 53,
        "country": "Cook Islands",
        "phoneCode": "+682",
        "alt": "CK"
    },
    {
        "id": 54,
        "country": "Costa Rica",
        "phoneCode": "+506",
        "alt": "CR"
    },
    {
        "id": 55,
        "country": "Côte d’Ivoire",
        "phoneCode": "+225",
        "alt": "CI"
    },
    {
        "id": 56,
        "country": "Croatia",
        "phoneCode": "+385",
        "alt": "HR"
    },
    {
        "id": 57,
        "country": "Curaçao",
        "phoneCode": "+599",
        "alt": "CW"
    },
    {
        "id": 58,
        "country": "Cyprus",
        "phoneCode": "+357",
        "alt": "CY"
    },
    {
        "id": 59,
        "country": "Czechia",
        "phoneCode": "+420",
        "alt": "CZ"
    },
    {
        "id": 60,
        "country": "Denmark",
        "phoneCode": "+45",
        "alt": "DK"
    },
    {
        "id": 61,
        "country": "Djibouti",
        "phoneCode": "+253",
        "alt": "DJ"
    },
    {
        "id": 62,
        "country": "Dominica",
        "phoneCode": "+1",
        "alt": "DM"
    },
    {
        "id": 63,
        "country": "Dominican Republic",
        "phoneCode": "+1",
        "alt": "DO"
    },
    {
        "id": 64,
        "country": "Ecuador",
        "phoneCode": "+593",
        "alt": "EC"
    },
    {
        "id": 65,
        "country": "Egypt",
        "phoneCode": "+20",
        "alt": "EG"
    },
    {
        "id": 66,
        "country": "El Salvador",
        "phoneCode": "+503",
        "alt": "SV"
    },
    {
        "id": 67,
        "country": "Equatorial Guinea",
        "phoneCode": "+240",
        "alt": "GQ"
    },
    {
        "id": 68,
        "country": "Eritrea",
        "phoneCode": "+291",
        "alt": "ER"
    },
    {
        "id": 69,
        "country": "Estonia",
        "phoneCode": "+372",
        "alt": "EE"
    },
    {
        "id": 70,
        "country": "Eswatini",
        "phoneCode": "+268",
        "alt": "SZ"
    },
    {
        "id": 71,
        "country": "Ethiopia",
        "phoneCode": "+251",
        "alt": "ET"
    },
    {
        "id": 72,
        "country": "Falkland Islands",
        "phoneCode": "+500",
        "alt": "FK"
    },
    {
        "id": 73,
        "country": "Faroe Islands",
        "phoneCode": "+298",
        "alt": "FO"
    },
    {
        "id": 74,
        "country": "Fiji",
        "phoneCode": "+679",
        "alt": "FJ"
    },
    {
        "id": 75,
        "country": "Finland",
        "phoneCode": "+358",
        "alt": "FI"
    },
    {
        "id": 76,
        "country": "France",
        "phoneCode": "+33",
        "alt": "FR"
    },
    {
        "id": 77,
        "country": "French Guiana",
        "phoneCode": "+594",
        "alt": "GF"
    },
    {
        "id": 78,
        "country": "French Polynesia",
        "phoneCode": "+689",
        "alt": "PF"
    },
    {
        "id": 79,
        "country": "Gabon",
        "phoneCode": "+241",
        "alt": "GA"
    },
    {
        "id": 80,
        "country": "Gambia",
        "phoneCode": "+220",
        "alt": "GM"
    },
    {
        "id": 81,
        "country": "Georgia",
        "phoneCode": "+995",
        "alt": "GE"
    },
    {
        "id": 82,
        "country": "Germany",
        "phoneCode": "+49",
        "alt": "DE"
    },
    {
        "id": 83,
        "country": "Ghana",
        "phoneCode": "+233",
        "alt": "GH"
    },
    {
        "id": 84,
        "country": "Gibraltar",
        "phoneCode": "+350",
        "alt": "GI"
    },
    {
        "id": 85,
        "country": "Greece",
        "phoneCode": "+30",
        "alt": "GR"
    },
    {
        "id": 86,
        "country": "Greenland",
        "phoneCode": "+299",
        "alt": "GL"
    },
    {
        "id": 87,
        "country": "Grenada",
        "phoneCode": "+1",
        "alt": "GD"
    },
    {
        "id": 88,
        "country": "Guadeloupe",
        "phoneCode": "+590",
        "alt": "GP"
    },
    {
        "id": 89,
        "country": "Guam",
        "phoneCode": "+1",
        "alt": "GU"
    },
    {
        "id": 90,
        "country": "Guatemala",
        "phoneCode": "+502",
        "alt": "GT"
    },
    {
        "id": 91,
        "country": "Guernsey",
        "phoneCode": "+44",
        "alt": "GG"
    },
    {
        "id": 92,
        "country": "Guinea",
        "phoneCode": "+224",
        "alt": "GN"
    },
    {
        "id": 93,
        "country": "Guinea-Bissau",
        "phoneCode": "+245",
        "alt": "GW"
    },
    {
        "id": 94,
        "country": "Guyana",
        "phoneCode": "+592",
        "alt": "GY"
    },
    {
        "id": 95,
        "country": "Haiti",
        "phoneCode": "+509",
        "alt": "HT"
    },
    {
        "id": 96,
        "country": "Honduras",
        "phoneCode": "+504",
        "alt": "HN"
    },
    {
        "id": 97,
        "country": "Hong Kong SAR China",
        "phoneCode": "+852",
        "alt": "HK"
    },
    {
        "id": 98,
        "country": "Hungary",
        "phoneCode": "+36",
        "alt": "HU"
    },
    {
        "id": 99,
        "country": "Iceland",
        "phoneCode": "+354",
        "alt": "IS"
    },
    {
        "id": 100,
        "country": "India",
        "phoneCode": "+91",
        "alt": "IN"
    },
    {
        "id": 101,
        "country": "Indonesia",
        "phoneCode": "+62",
        "alt": "ID"
    },
    {
        "id": 102,
        "country": "Iraq",
        "phoneCode": "+964",
        "alt": "IQ"
    },
    {
        "id": 103,
        "country": "Ireland",
        "phoneCode": "+353",
        "alt": "IE"
    },
    {
        "id": 104,
        "country": "Isle of Man",
        "phoneCode": "+44",
        "alt": "IM"
    },
    {
        "id": 105,
        "country": "Israel",
        "phoneCode": "+972",
        "alt": "IL"
    },
    {
        "id": 106,
        "country": "Italy",
        "phoneCode": "+39",
        "alt": "IT"
    },
    {
        "id": 107,
        "country": "Jamaica",
        "phoneCode": "+1",
        "alt": "JM"
    },
    {
        "id": 108,
        "country": "Japan",
        "phoneCode": "+81",
        "alt": "JP"
    },
    {
        "id": 109,
        "country": "Jersey",
        "phoneCode": "+44",
        "alt": "JE"
    },
    {
        "id": 110,
        "country": "Jordan",
        "phoneCode": "+962",
        "alt": "JO"
    },
    {
        "id": 111,
        "country": "Kazakhstan",
        "phoneCode": "+7",
        "alt": "KZ"
    },
    {
        "id": 112,
        "country": "Kenya",
        "phoneCode": "+254",
        "alt": "KE"
    },
    {
        "id": 113,
        "country": "Kiribati",
        "phoneCode": "+686",
        "alt": "KI"
    },
    {
        "id": 114,
        "country": "Kosovo",
        "phoneCode": "+383",
        "alt": "XK"
    },
    {
        "id": 115,
        "country": "Kuwait",
        "phoneCode": "+965",
        "alt": "KW"
    },
    {
        "id": 116,
        "country": "Kyrgyzstan",
        "phoneCode": "+996",
        "alt": "KG"
    },
    {
        "id": 117,
        "country": "Laos",
        "phoneCode": "+856",
        "alt": "LA"
    },
    {
        "id": 118,
        "country": "Latvia",
        "phoneCode": "+371",
        "alt": "LV"
    },
    {
        "id": 119,
        "country": "Lebanon",
        "phoneCode": "+961",
        "alt": "LB"
    },
    {
        "id": 120,
        "country": "Lesotho",
        "phoneCode": "+266",
        "alt": "LS"
    },
    {
        "id": 121,
        "country": "Liberia",
        "phoneCode": "+231",
        "alt": "LR"
    },
    {
        "id": 122,
        "country": "Libya",
        "phoneCode": "+218",
        "alt": "LY"
    },
    {
        "id": 123,
        "country": "Liechtenstein",
        "phoneCode": "+423",
        "alt": "LI"
    },
    {
        "id": 124,
        "country": "Lithuania",
        "phoneCode": "+370",
        "alt": "LT"
    },
    {
        "id": 125,
        "country": "Luxembourg",
        "phoneCode": "+352",
        "alt": "LU"
    },
    {
        "id": 126,
        "country": "Macao SAR China",
        "phoneCode": "+853",
        "alt": "MO"
    },
    {
        "id": 127,
        "country": "Madagascar",
        "phoneCode": "+261",
        "alt": "MG"
    },
    {
        "id": 128,
        "country": "Malawi",
        "phoneCode": "+265",
        "alt": "MW"
    },
    {
        "id": 129,
        "country": "Malaysia",
        "phoneCode": "+60",
        "alt": "MY"
    },
    {
        "id": 130,
        "country": "Maldives",
        "phoneCode": "+960",
        "alt": "MV"
    },
    {
        "id": 131,
        "country": "Mali",
        "phoneCode": "+223",
        "alt": "ML"
    },
    {
        "id": 132,
        "country": "Malta",
        "phoneCode": "+356",
        "alt": "MT"
    },
    {
        "id": 133,
        "country": "Marshall Islands",
        "phoneCode": "+692",
        "alt": "MH"
    },
    {
        "id": 134,
        "country": "Martinique",
        "phoneCode": "+596",
        "alt": "MQ"
    },
    {
        "id": 135,
        "country": "Mauritania",
        "phoneCode": "+222",
        "alt": "MR"
    },
    {
        "id": 136,
        "country": "Mauritius",
        "phoneCode": "+230",
        "alt": "MU"
    },
    {
        "id": 137,
        "country": "Mayotte",
        "phoneCode": "+262",
        "alt": "YT"
    },
    {
        "id": 138,
        "country": "Mexico",
        "phoneCode": "+52",
        "alt": "MX"
    },
    {
        "id": 139,
        "country": "Micronesia",
        "phoneCode": "+691",
        "alt": "FM"
    },
    {
        "id": 140,
        "country": "Moldova",
        "phoneCode": "+373",
        "alt": "MD"
    },
    {
        "id": 141,
        "country": "Monaco",
        "phoneCode": "+377",
        "alt": "MC"
    },
    {
        "id": 142,
        "country": "Mongolia",
        "phoneCode": "+976",
        "alt": "MN"
    },
    {
        "id": 143,
        "country": "Montenegro",
        "phoneCode": "+382",
        "alt": "ME"
    },
    {
        "id": 144,
        "country": "Montserrat",
        "phoneCode": "+1",
        "alt": "MS"
    },
    {
        "id": 145,
        "country": "Morocco",
        "phoneCode": "+212",
        "alt": "MA"
    },
    {
        "id": 146,
        "country": "Mozambique",
        "phoneCode": "+258",
        "alt": "MZ"
    },
    {
        "id": 147,
        "country": "Myanmar (Burma)",
        "phoneCode": "+95",
        "alt": "MM"
    },
    {
        "id": 148,
        "country": "Namibia",
        "phoneCode": "+264",
        "alt": "NA"
    },
    {
        "id": 149,
        "country": "Nauru",
        "phoneCode": "+674",
        "alt": "NR"
    },
    {
        "id": 150,
        "country": "Nepal",
        "phoneCode": "+977",
        "alt": "NP"
    },
    {
        "id": 151,
        "country": "Netherlands",
        "phoneCode": "+31",
        "alt": "NL"
    },
    {
        "id": 152,
        "country": "New Caledonia",
        "phoneCode": "+687",
        "alt": "NC"
    },
    {
        "id": 153,
        "country": "New Zealand",
        "phoneCode": "+64",
        "alt": "NZ"
    },
    {
        "id": 154,
        "country": "Nicaragua",
        "phoneCode": "+505",
        "alt": "NI"
    },
    {
        "id": 155,
        "country": "Niger",
        "phoneCode": "+227",
        "alt": "NE"
    },
    {
        "id": 156,
        "country": "Nigeria",
        "phoneCode": "+234",
        "alt": "NG"
    },
    {
        "id": 157,
        "country": "Niue",
        "phoneCode": "+683",
        "alt": "NU"
    },
    {
        "id": 158,
        "country": "Norfolk Island",
        "phoneCode": "+672",
        "alt": "NF"
    },
    {
        "id": 159,
        "country": "North Macedonia",
        "phoneCode": "+389",
        "alt": "MK"
    },
    {
        "id": 160,
        "country": "Northern Mariana Islands",
        "phoneCode": "+1",
        "alt": "MP"
    },
    {
        "id": 161,
        "country": "Norway",
        "phoneCode": "+47",
        "alt": "NO"
    },
    {
        "id": 162,
        "country": "Oman",
        "phoneCode": "+968",
        "alt": "OM"
    },
    {
        "id": 163,
        "country": "Pakistan",
        "phoneCode": "+92",
        "alt": "PK"
    },
    {
        "id": 164,
        "country": "Palau",
        "phoneCode": "+680",
        "alt": "PW"
    },
    {
        "id": 165,
        "country": "Palestinian Territories",
        "phoneCode": "+970",
        "alt": "PS"
    },
    {
        "id": 166,
        "country": "Panama",
        "phoneCode": "+507",
        "alt": "PA"
    },
    {
        "id": 167,
        "country": "Papua New Guinea",
        "phoneCode": "+675",
        "alt": "PG"
    },
    {
        "id": 168,
        "country": "Paraguay",
        "phoneCode": "+595",
        "alt": "PY"
    },
    {
        "id": 169,
        "country": "Peru",
        "phoneCode": "+51",
        "alt": "PE"
    },
    {
        "id": 170,
        "country": "Philippines",
        "phoneCode": "+63",
        "alt": "PH"
    },
    {
        "id": 171,
        "country": "Pitcairn Islands",
        "phoneCode": "+64",
        "alt": "PN"
    },
    {
        "id": 172,
        "country": "Poland",
        "phoneCode": "+48",
        "alt": "PL"
    },
    {
        "id": 173,
        "country": "Portugal",
        "phoneCode": "+351",
        "alt": "PT"
    },
    {
        "id": 174,
        "country": "Puerto Rico",
        "phoneCode": "+1",
        "alt": "PR"
    },
    {
        "id": 175,
        "country": "Qatar",
        "phoneCode": "+974",
        "alt": "QA"
    },
    {
        "id": 176,
        "country": "Réunion",
        "phoneCode": "+262",
        "alt": "RE"
    },
    {
        "id": 177,
        "country": "Romania",
        "phoneCode": "+40",
        "alt": "RO"
    },
    {
        "id": 178,
        "country": "Rwanda",
        "phoneCode": "+250",
        "alt": "RW"
    },
    {
        "id": 179,
        "country": "Samoa",
        "phoneCode": "+685",
        "alt": "WS"
    },
    {
        "id": 180,
        "country": "San Marino",
        "phoneCode": "+378",
        "alt": "SM"
    },
    {
        "id": 181,
        "country": "São Tomé & Príncipe",
        "phoneCode": "+239",
        "alt": "ST"
    },
    {
        "id": 182,
        "country": "Saudi Arabia",
        "phoneCode": "+966",
        "alt": "SA"
    },
    {
        "id": 183,
        "country": "Senegal",
        "phoneCode": "+221",
        "alt": "SN"
    },
    {
        "id": 184,
        "country": "Serbia",
        "phoneCode": "+381",
        "alt": "RS"
    },
    {
        "id": 185,
        "country": "Seychelles",
        "phoneCode": "+248",
        "alt": "SC"
    },
    {
        "id": 186,
        "country": "Sierra Leone",
        "phoneCode": "+232",
        "alt": "SL"
    },
    {
        "id": 187,
        "country": "Singapore",
        "phoneCode": "+65",
        "alt": "SG"
    },
    {
        "id": 188,
        "country": "Sint Maarten",
        "phoneCode": "+1",
        "alt": "SX"
    },
    {
        "id": 189,
        "country": "Slovakia",
        "phoneCode": "+421",
        "alt": "SK"
    },
    {
        "id": 190,
        "country": "Slovenia",
        "phoneCode": "+386",
        "alt": "SI"
    },
    {
        "id": 191,
        "country": "Solomon Islands",
        "phoneCode": "+677",
        "alt": "SB"
    },
    {
        "id": 192,
        "country": "Somalia",
        "phoneCode": "+252",
        "alt": "SO"
    },
    {
        "id": 193,
        "country": "South Africa",
        "phoneCode": "+27",
        "alt": "ZA"
    },
    {
        "id": 194,
        "country": "South Korea",
        "phoneCode": "+82",
        "alt": "KR"
    },
    {
        "id": 195,
        "country": "South Sudan",
        "phoneCode": "+211",
        "alt": "SS"
    },
    {
        "id": 196,
        "country": "Spain",
        "phoneCode": "+34",
        "alt": "ES"
    },
    {
        "id": 197,
        "country": "Sri Lanka",
        "phoneCode": "+94",
        "alt": "LK"
    },
    {
        "id": 198,
        "country": "St. Barthélemy",
        "phoneCode": "+590",
        "alt": "BL"
    },
    {
        "id": 199,
        "country": "St. Helena",
        "phoneCode": "+290",
        "alt": "SH"
    },
    {
        "id": 200,
        "country": "St. Kitts & Nevis",
        "phoneCode": "+1",
        "alt": "KN"
    },
    {
        "id": 201,
        "country": "St. Lucia",
        "phoneCode": "+1",
        "alt": "LC"
    },
    {
        "id": 202,
        "country": "St. Martin",
        "phoneCode": "+590",
        "alt": "MF"
    },
    {
        "id": 203,
        "country": "St. Pierre & Miquelon",
        "phoneCode": "+508",
        "alt": "PM"
    },
    {
        "id": 204,
        "country": "St. Vincent & Grenadines",
        "phoneCode": "+1",
        "alt": "VC"
    },
    {
        "id": 205,
        "country": "Suriname",
        "phoneCode": "+597",
        "alt": "SR"
    },
    {
        "id": 206,
        "country": "Svalbard & Jan Mayen",
        "phoneCode": "+47",
        "alt": "SJ"
    },
    {
        "id": 207,
        "country": "Sweden",
        "phoneCode": "+46",
        "alt": "SE"
    },
    {
        "id": 208,
        "country": "Switzerland",
        "phoneCode": "+41",
        "alt": "CH"
    },
    {
        "id": 209,
        "country": "Taiwan",
        "phoneCode": "+886",
        "alt": "TW"
    },
    {
        "id": 210,
        "country": "Tajikistan",
        "phoneCode": "+992",
        "alt": "TJ"
    },
    {
        "id": 211,
        "country": "Tanzania",
        "phoneCode": "+255",
        "alt": "TZ"
    },
    {
        "id": 212,
        "country": "Thailand",
        "phoneCode": "+66",
        "alt": "TH"
    },
    {
        "id": 213,
        "country": "Timor-Leste",
        "phoneCode": "+670",
        "alt": "TL"
    },
    {
        "id": 214,
        "country": "Togo",
        "phoneCode": "+228",
        "alt": "TG"
    },
    {
        "id": 215,
        "country": "Tokelau",
        "phoneCode": "+690",
        "alt": "TK"
    },
    {
        "id": 216,
        "country": "Tonga",
        "phoneCode": "+676",
        "alt": "TO"
    },
    {
        "id": 217,
        "country": "Trinidad & Tobago",
        "phoneCode": "+1",
        "alt": "TT"
    },
    {
        "id": 218,
        "country": "Tunisia",
        "phoneCode": "+216",
        "alt": "TN"
    },
    {
        "id": 219,
        "country": "Turkey",
        "phoneCode": "+90",
        "alt": "TR"
    },
    {
        "id": 220,
        "country": "Turkmenistan",
        "phoneCode": "+993",
        "alt": "TM"
    },
    {
        "id": 221,
        "country": "Turks & Caicos Islands",
        "phoneCode": "+1",
        "alt": "TC"
    },
    {
        "id": 222,
        "country": "Tuvalu",
        "phoneCode": "+688",
        "alt": "TV"
    },
    {
        "id": 223,
        "country": "U.S. Outlying Islands",
        "phoneCode": "+1",
        "alt": "UM"
    },
    {
        "id": 224,
        "country": "U.S. Virgin Islands",
        "phoneCode": "+1",
        "alt": "VI"
    },
    {
        "id": 225,
        "country": "Uganda",
        "phoneCode": "+256",
        "alt": "UG"
    },
    {
        "id": 226,
        "country": "Ukraine",
        "phoneCode": "+380",
        "alt": "UA"
    },
    {
        "id": 227,
        "country": "United Arab Emirates",
        "phoneCode": "+971",
        "alt": "AE"
    },
    {
        "id": 228,
        "country": "United Kingdom",
        "phoneCode": "+44",
        "alt": "GB"
    },
    {
        "id": 229,
        "country": "United States",
        "phoneCode": "+1",
        "alt": "US"
    },
    {
        "id": 230,
        "country": "Uruguay",
        "phoneCode": "+598",
        "alt": "UY"
    },
    {
        "id": 231,
        "country": "Uzbekistan",
        "phoneCode": "+998",
        "alt": "UZ"
    },
    {
        "id": 232,
        "country": "Vanuatu",
        "phoneCode": "+678",
        "alt": "VU"
    },
    {
        "id": 233,
        "country": "Vatican City",
        "phoneCode": "+379",
        "alt": "VA"
    },
    {
        "id": 234,
        "country": "Venezuela",
        "phoneCode": "+58",
        "alt": "VE"
    },
    {
        "id": 235,
        "country": "Vietnam",
        "phoneCode": "+84",
        "alt": "VN"
    },
    {
        "id": 236,
        "country": "Wallis & Futuna",
        "phoneCode": "+681",
        "alt": "WF"
    },
    {
        "id": 237,
        "country": "Western Sahara",
        "phoneCode": "+212",
        "alt": "EH"
    },
    {
        "id": 238,
        "country": "Yemen",
        "phoneCode": "+967",
        "alt": "YE"
    },
    {
        "id": 239,
        "country": "Zambia",
        "phoneCode": "+260",
        "alt": "ZM"
    },
    {
        "id": 240,
        "country": "Zimbabwe",
        "phoneCode": "+263",
        "alt": "ZW"
    }
]