import React, { useRef, useState } from "react";
import styles from "./EnterPhoneCode.module.scss";
import { ReactComponent as Tinder } from "../../assets/tinderpopup.svg";
import { ReactComponent as Cross } from "../../assets/cross.svg";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import {
  closePopup,
  openPopup,
  setError,
  setLoading,
} from "../../features/popups/popupsSlice";
import axios from "axios";
import { setMail } from "../../features/items/itemSlice";
import { setPhone } from "../../features/phone/phoneSlice";
import { useNavigate } from "react-router-dom";

const EnterPhoneCode = ({ isActive, fnc, fnc2 }) => {
  const dispatch = useDispatch();
  const [code, setCode] = useState(["", "", "", "", "", ""]);
  const inputsRef = useRef([]);
  const phone = useSelector((state) => state.item.document.phone);
  const id = useSelector((state) => state.item.document._id);
  const activeCountry = useSelector((state) => state.countries.activeCountry);
  const isCodePopupOpen = useSelector((state) => state.popups.codePopup.isOpen);
  const [isDisabled, setIsDisabled] = useState(true);
  const error = useSelector((state) => state.popups.codePopup.error);
  const navigate = useNavigate();

  const handleCloseClick = async () => {
    dispatch(closePopup("codePopup")); // Отправляем действие для закрытия попапа кода
    try {
      const { data } = await axios.delete(
        `${process.env.REACT_APP_URL}/delete/${id}`
      );

      if (data) {
        if (data.err) {
        } else if (!data.err) {
          console.log(data.msg);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handlePaste = (e) => {
    e.preventDefault(); // Предотвращаем стандартное поведение вставки

    // Получаем текст из буфера обмена
    const pastedText = e.clipboardData.getData("text/plain");

    // Создаем новый массив для кода
    let newCode = [...code];

    // Разбиваем текст на символы и обрабатываем каждый символ
    [...pastedText].forEach((char, index) => {
      // Проверяем, что символ является числом и индекс меньше длины кода
      if (!isNaN(char) && index < code.length) {
        newCode[index] = char;
      }
    });

    // Обновляем состояние кода
    setCode(newCode);

    // Проверяем, все ли инпуты заполнены
    const allFilled = newCode.every((input) => input.trim() !== "");
    setIsDisabled(!allFilled);

    // Перемещаем фокус на последний инпут
    inputsRef.current[code.length - 1].focus();
  };

  const handleServerError = (error) => {
    dispatch(setError({ popup: "codePopup", error })); // Отправляем ошибку в Redux
  };

  const handleChange = (index, event) => {
    const newValue = event.target.value;
    handleServerError(null);

    // Если вводимый символ является числом и длина кода не превышает 6
    if (!isNaN(newValue) && newValue.length <= 1) {
      const newCode = [...code];
      newCode[index] = newValue;
      setCode(newCode);

      // Переход к следующему инпуту, если возможно
      if (index < code.length - 1) {
        inputsRef.current[index + 1].focus();
      }

      const allFilled = newCode.every((input) => input.trim() !== "");
      setIsDisabled(!allFilled);
    }
  };

  const handleBackspace = (index, event) => {
    // Переход к предыдущему инпуту при нажатии на Backspace, если текущий инпут пуст
    if (event.key === "Backspace") {
      event.preventDefault(); // Предотвращаем стандартное поведение Backspace (удаление символа)
      const newCode = [...code];
      newCode[index] = ""; // Удаляем значение из текущего инпута
      newCode[index - 1] = ""; // Удаляем значение из текущего инпута
      setCode(newCode);
      if (index === 0) {
        inputsRef.current[index].focus(); // Перемещаем фокус на предыдущий инпут
        return;
      }
      inputsRef.current[index - 1].focus(); // Перемещаем фокус на предыдущий инпут

      const allFilled = newCode.every((input) => input.trim() !== "");
      setIsDisabled(!allFilled);
    }
    // } else if (event.key === "Backspace" && code[index] === "" && index > 0) {
    //   event.preventDefault(); // Предотвращаем стандартное поведение Backspace (удаление символа)
    //   inputsRef.current[index - 1].focus(); // Перемещаем фокус на предыдущий инпут
    // }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      dispatch(setLoading({ popup: "codePopup", isLoading: true }));
      dispatch(closePopup("codePopup"));
      const { data } = await axios.post(
        `${process.env.REACT_APP_URL}/check-code/${id}`,
        {
          code: code.join(""),
        }
      );

      setCode(["", "", "", "", "", ""]);

      if (data) {
        dispatch(setLoading({ popup: "codePopup", isLoading: false }));
        if (data.err) {
          if (data.msg === "Too many wrong attempts") {
            dispatch(openPopup("phoneNumberPopup"));
            handleServerError(null);
            return;
          } else if (data.msg === "Too many attempts") {
            dispatch(openPopup("phoneNumberPopup"));
            handleServerError(null);
          } else if (data.msg === "Code is not valid") {
            dispatch(openPopup("codePopup"));
            handleServerError(true);
          } else if (data.msg === "You are authorized") {
            handleServerError(null);
            navigate("/get-premium");
          } else {
            dispatch(openPopup("phoneNumberPopup"));
            handleServerError(null);
          }
          console.log(data.msg);
        } else if (!data.err) {
          console.log(data.msg);
          dispatch(setMail(data.email));
          dispatch(openPopup("emailPopup"));
          handleServerError(null);
        }
      }
    } catch (error) {
      console.log(error);
      dispatch(openPopup("phoneNumberPopup"));
      dispatch(closePopup("codePopup"));
      dispatch(setLoading({ popup: "codePopup", isLoading: false }));
      dispatch(setPhone(""));
      handleServerError(null);
    }
  };

  const handleResendCode = async () => {
    dispatch(closePopup("codePopup")); // Отправляем действие для закрытия попапа кода
    dispatch(setLoading({ popup: "codePopup", isLoading: true }));
    handleServerError(null);
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_URL}/resend/${id}`
      );

      if (data) {
        if (data.err) {
          dispatch(openPopup("phoneNumberPopup")); // Отправляем действие для закрытия попапа кода
          dispatch(setError({ popup: "phoneNumberPopup", error: true })); // Отправляем действие для закрытия попапа кода
          dispatch(setLoading({ popup: "codePopup", isLoading: false }));
        } else if (!data.err) {
          console.log(data.msg);
          dispatch(openPopup("codePopup")); // Отправляем действие для закрытия попапа кода
          dispatch(setLoading({ popup: "codePopup", isLoading: false }));
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div
      className={classNames(styles.popup, { [styles.active]: isCodePopupOpen })}
    >
      <div className={styles.popupInner}>
        <div>
          <Cross onClick={() => handleCloseClick()} className={styles.cross} />
          <Tinder />
        </div>
        <h3>Enter your code</h3>
        <div className={styles.top}>
          <span>{activeCountry.phoneCode + phone}</span>
          <button onClick={handleResendCode}>Resend</button>
        </div>
        <form
          className={styles.form}
          onSubmit={(e) => handleSubmit(e)}
          onPaste={handlePaste}
        >
          <div className={styles.code}>
            {code.map((value, index) => (
              <input
                key={index}
                ref={(input) => (inputsRef.current[index] = input)}
                type="tel"
                maxLength="1"
                value={value}
                onChange={(e) => handleChange(index, e)}
                onKeyDown={(e) => handleBackspace(index, e)}
              />
            ))}
          </div>
          {error ? (
            <span className={styles.error}>
              The code you entered is invalid. Please try again
            </span>
          ) : (
            ""
          )}
          <button disabled={isDisabled} type="submit">
            Next
          </button>
        </form>
      </div>
    </div>
  );
};

export default EnterPhoneCode;
