import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    phone: ""
}

export const phoneSlice = createSlice({
    name: 'phone',
    initialState,
    reducers: {
        setPhone(state, action) {
            state.phone = action.payload
        }
    },
})

// Action creators are generated for each case reducer function
export const { setPhone } = phoneSlice.actions

export default phoneSlice.reducer