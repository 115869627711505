import { configureStore } from '@reduxjs/toolkit'
import phoneReducer from '../features/phone/phoneSlice'
import countriesReducer from '../features/countries/countriesSlice';
import popupsReducer from '../features/popups/popupsSlice';
import itemReducer from '../features/items/itemSlice';

export const store = configureStore({
    reducer: {
        phone: phoneReducer,
        countries: countriesReducer,
        popups: popupsReducer,
        item: itemReducer,
    },
})