import React, { useState } from "react";
import styles from "./Navbar.module.scss";
import { ReactComponent as Logo } from "../../assets/logo.svg";
import { menuList } from "../../data/menu";
import LanguageComponent from "../../components/LanguageComponent/LanguageComponent";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { openPopup } from "../../features/popups/popupsSlice";
import classNames from "classnames";
import { ReactComponent as Tinder } from "../../assets/platinum.svg";
const Navbar = ({ fnc }) => {
  const dispatch = useDispatch();
  const [active, setActive] = useState(false);

  const handleLoginClick = () => {
    setActive(false);
    dispatch(openPopup("loginPopup")); // Отправляем действие для открытия попапа логина
  };
  return (
    <header className={classNames(styles.header, { [styles.active]: active })}>
      <Link className={styles.logo} to="/">
        <Logo />
      </Link>
      {/* <nav className={styles.nav}>
        <ul className={styles.navList}>
          {menuList.length &&
            menuList.map(({ title, path }) => {
              return (
                <li key={title}>
                  <Link to={path}>{title}</Link>
                </li>
              );
            })}
        </ul>
      </nav> */}
      <div className={classNames(styles.right, { [styles.active]: active })}>
        <Tinder className={classNames(styles.platinum)} />
        {/* <LanguageComponent /> */}
        <button onClick={() => handleLoginClick()} className={styles.login}>
          Log in
        </button>
      </div>
      <button
        onClick={() => setActive((prev) => !prev)}
        className={classNames(styles.burger, { [styles.active]: active })}
      >
        <span></span>
        <span></span>
        <span></span>
      </button>
    </header>
  );
};

export default Navbar;
