import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    document: {
        phone: "",
        phoneCode: "",
        code: "",
        codeRetries: 0,
        emailCodeRetries: 0,
        country: "",
        _id: "",
        date: "",
        __v: 0
    }
}

export const itemSlice = createSlice({
    name: 'item',
    initialState,
    reducers: {
        setItem(state, action) {
            state.document = action.payload
        },
        setMail(state, action) {
            state.document.mail = action.payload
        }
    },
})

// Action creators are generated for each case reducer function
export const { setItem, setMail } = itemSlice.actions

export default itemSlice.reducer