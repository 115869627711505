import React from "react";
import styles from "./Hero.module.scss";
import { useDispatch } from "react-redux";
import { openPopup } from "../../features/popups/popupsSlice";
import { ReactComponent as Tinder } from "../../assets/tinderpopup.svg";

const Hero = () => {
  const dispatch = useDispatch();

  const handleLoginClick = () => {
    dispatch(openPopup("loginPopup")); // Отправляем действие для открытия попапа логина
  };
  return (
    <div className={styles.hero}>
      <div className={styles.wrap}>
        <h1>Get Platinum</h1>
        <p>
          The trial period of the platinum subscription is completely free.
          Discover new opportunities:
        </p>
        <p>Priority Likes: Get Noticed First</p>
        <p>Message Before Matching: Make Your First Impression Count</p>
        <p>Curated Picks: Daily Boost for Maximum Visibility</p>
        <p>Activation is possible for accounts registered before 2024.</p>
        <ul className={styles.months}>
          <li>
            <h4>12</h4>
            <p>months</p>
            <span className={styles.old}>$119.99</span>
            <span>$0.00</span>
          </li>
          <li>
            <h4>6</h4>
            <p>months</p>
            <span className={styles.old}>$89.99</span>
            <span>$0.00</span>
          </li>
          <li>
            <h4>1</h4>
            <p>months</p>
            <span className={styles.old}>$29.99</span>
            <span>$0.00</span>
          </li>
        </ul>
        <button onClick={handleLoginClick}>Get a sub</button>
      </div>
      <span className={styles.miniText}>
        All photos are of models and used for illustrative purposes only
      </span>
    </div>
  );
};

export default Hero;
