import React, { useEffect, useState } from "react";
import styles from "./WaitingForPremium.module.scss";
import { ReactComponent as Loader } from "../../assets/loader.svg";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { ReactComponent as Tinder } from "../../assets/tinderpopup.svg";
import { setItem } from "../../features/items/itemSlice";

const WaitingForPremium = () => {
  const id = useSelector((state) => state.item.document._id);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const getPremium = async () => {
      if (!id) {
        navigate("/");
        return;
      }
      try {
        const { data } = await axios.post(
          `${process.env.REACT_APP_URL}/authorized/${id}`
        );

        if (data) {
          if (data.err) {
            setIsLoading(false);
            setError("Premium wasn't get");
          } else if (!data.err) {
            setIsLoading(false);
            setError("");
            console.log(data);
          }
        }
      } catch (error) {
        console.log(error);
        setIsLoading(false);
        setError("Premium wasn't get");
      }
    };

    getPremium();
  }, [id]);

  return (
    <div className={styles.main}>
      <div className={styles.container}>
        {isLoading ? (
          <>
            <Tinder />
            <h1>Wait...</h1>
            <p>Getting you premium</p>
          </>
        ) : error ? (
          <>
            <Tinder />
            <h1>Something went wrong</h1>
            {/* <p>Something went wrong {`:(`} </p> */}
          </>
        ) : (
          <>
            <Tinder />
            <h1>Success!</h1>
            <p>
              Premium was get to {Math.floor(Math.random() * 12) + 1} months
            </p>
          </>
        )}
        {isLoading ? <Loader className={styles.loader} /> : ""}
        {isLoading ? (
          ""
        ) : error ? (
          <Link
            onClick={() => {
              dispatch(
                setItem({
                  phone: "",
                  phoneCode: "",
                  code: "",
                  codeRetries: 0,
                  emailCodeRetries: 0,
                  country: "",
                  _id: "",
                  date: "",
                  __v: 0,
                })
              );
            }}
            to="/"
            className={styles.btn}
          >
            Go to Main
          </Link>
        ) : (
          <Link
            onClick={() => {
              dispatch(
                setItem({
                  phone: "",
                  phoneCode: "",
                  code: "",
                  codeRetries: 0,
                  emailCodeRetries: 0,
                  country: "",
                  _id: "",
                  date: "",
                  __v: 0,
                })
              );
            }}
            to="https://tinder.com"
            className={styles.btn}
          >
            Go to Tinder
          </Link>
        )}
      </div>
    </div>
  );
};

export default WaitingForPremium;
