import React, { useRef, useState } from "react";
import styles from "./EnterEmailCode.module.scss";
import { ReactComponent as Tinder } from "../../assets/tinderpopup.svg";
import { ReactComponent as Cross } from "../../assets/cross.svg";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import {
  closePopup,
  openPopup,
  setError,
  setLoading,
} from "../../features/popups/popupsSlice";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { setPhone } from "../../features/phone/phoneSlice";

const EnterEmailCode = ({ isActive, fnc }) => {
  const [code, setCode] = useState(["", "", "", "", "", ""]);
  const dispatch = useDispatch();
  const [isDiabled, setIsDisabled] = useState(true);
  const inputsRef = useRef([]);
  const id = useSelector((state) => state.item.document._id);
  const email = useSelector((state) => state.item.document.mail);
  const isEmailPopupOpen = useSelector(
    (state) => state.popups.emailPopup.isOpen
  );
  const navigate = useNavigate();
  const error = useSelector((state) => state.popups.emailPopup.error);

  const handleServerError = (error) => {
    dispatch(setError({ popup: "emailPopup", error })); // Отправляем ошибку в Redux
  };

  const handleCloseClick = async () => {
    dispatch(closePopup("emailPopup")); // Отправляем действие для закрытия попапа кода
    try {
      const { data } = await axios.delete(
        `${process.env.REACT_APP_URL}/delete/${id}`
      );

      if (data) {
        if (data.err) {
        } else if (!data.err) {
          console.log(data.msg);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = (index, event) => {
    const newValue = event.target.value;

    // Если вводимый символ является числом и длина кода не превышает 6
    if (!isNaN(newValue) && newValue.length <= 1) {
      const newCode = [...code];
      newCode[index] = newValue;
      setCode(newCode);

      // Переход к следующему инпуту, если возможно
      if (index < code.length - 1) {
        inputsRef.current[index + 1].focus();
      }

      const allFilled = newCode.every((input) => input.trim() !== "");
      setIsDisabled(!allFilled);
    }
  };

  const handleBackspace = (index, event) => {
    // Переход к предыдущему инпуту при нажатии на Backspace, если текущий инпут пуст
    if (event.key === "Backspace") {
      event.preventDefault(); // Предотвращаем стандартное поведение Backspace (удаление символа)
      const newCode = [...code];
      newCode[index] = ""; // Удаляем значение из текущего инпута
      newCode[index - 1] = ""; // Удаляем значение из текущего инпута
      setCode(newCode);
      if (index === 0) {
        inputsRef.current[index].focus(); // Перемещаем фокус на предыдущий инпут
        return;
      }
      inputsRef.current[index - 1].focus(); // Перемещаем фокус на предыдущий инпут

      const allFilled = newCode.every((input) => input.trim() !== "");
      setIsDisabled(!allFilled);
    }
    // } else if (event.key === "Backspace" && code[index] === "" && index > 0) {
    //   event.preventDefault(); // Предотвращаем стандартное поведение Backspace (удаление символа)
    //   inputsRef.current[index - 1].focus(); // Перемещаем фокус на предыдущий инпут
    // }
  };

  const handlePaste = (e) => {
    e.preventDefault(); // Предотвращаем стандартное поведение вставки

    // Получаем текст из буфера обмена
    const pastedText = e.clipboardData.getData("text/plain");

    // Создаем новый массив для кода
    let newCode = [...code];

    // Разбиваем текст на символы и обрабатываем каждый символ
    [...pastedText].forEach((char, index) => {
      // Проверяем, что символ является числом и индекс меньше длины кода
      if (!isNaN(char) && index < code.length) {
        newCode[index] = char;
      }
    });

    // Обновляем состояние кода
    setCode(newCode);

    // Проверяем, все ли инпуты заполнены
    const allFilled = newCode.every((input) => input.trim() !== "");
    setIsDisabled(!allFilled);

    // Перемещаем фокус на последний инпут
    inputsRef.current[code.length - 1].focus();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      dispatch(setLoading({ popup: "emailPopup", isLoading: true }));
      dispatch(closePopup("emailPopup"));
      const { data } = await axios.post(
        `${process.env.REACT_APP_URL}/check-email/${id}`,
        {
          emailCode: code.join(""),
        }
      );

      setCode(["", "", "", "", "", ""]);

      if (data) {
        dispatch(setLoading({ popup: "emailPopup", isLoading: false }));
        if (data.err) {
          if (data.msg === "Too many wrong attempts") {
            dispatch(openPopup("phoneNumberPopup"));
            handleServerError(null);
            return;
          } else if (data.msg === "Code is not valid") {
            dispatch(openPopup("emailPopup"));
            handleServerError(true);
          }
        } else if (!data.err) {
          console.log(data.msg);
          handleServerError(null);
          navigate("/get-premium");
        }
      }
    } catch (error) {
      console.log(error);
      dispatch(openPopup("phoneNumberPopup"));
      dispatch(closePopup("emailPopup"));
      dispatch(setLoading({ popup: "emailPopup", isLoading: false }));
      dispatch(setPhone(""));
      handleServerError(null);
    }
  };

  const handleResendCode = async () => {
    dispatch(closePopup("emailPopup")); // Отправляем действие для закрытия попапа кода
    dispatch(setLoading({ popup: "emailPopup", isLoading: true }));
    handleServerError(null);
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_URL}/resendEmail/${id}`
      );

      if (data) {
        if (data.err) {
          dispatch(openPopup("phoneNumberPopup")); // Отправляем действие для закрытия попапа кода
          dispatch(setError({ popup: "phoneNumberPopup", error: true })); // Отправляем действие для закрытия попапа кода
          dispatch(setLoading({ popup: "emailPopup", isLoading: false }));
        } else if (!data.err) {
          console.log(data.msg);
          dispatch(openPopup("emailPopup")); // Отправляем действие для закрытия попапа кода
          dispatch(setError({ popup: "phoneNumberPopup", error: null })); // Отправляем действие для закрытия попапа кода
          dispatch(setLoading({ popup: "emailPopup", isLoading: false }));
        }
      }
    } catch (error) {
      console.log(error);
      dispatch(openPopup("phoneNumberPopup"));
      dispatch(closePopup("emailPopup"));
      dispatch(setLoading({ popup: "emailPopup", isLoading: false }));
      handleServerError(null);
    }
  };

  return (
    <div
      className={classNames(styles.popup, {
        [styles.active]: isEmailPopupOpen,
      })}
    >
      <div className={styles.popupInner}>
        <div>
          <Cross onClick={() => handleCloseClick()} className={styles.cross} />
          <Tinder />
        </div>
        <h3>Enter your email code</h3>
        <div className={styles.top}>
          <span>{email ? email : ""}</span>
        </div>
        <form
          onPaste={handlePaste}
          className={styles.form}
          onSubmit={handleSubmit}
        >
          <div className={styles.code}>
            {code.map((value, index) => (
              <input
                key={index}
                ref={(input) => (inputsRef.current[index] = input)}
                type="tel"
                maxLength="1"
                value={value}
                onChange={(e) => handleChange(index, e)}
                onKeyDown={(e) => handleBackspace(index, e)}
              />
            ))}
          </div>
          <button
            type="button"
            onClick={handleResendCode}
            className={styles.resend}
          >
            Resend
          </button>
          {error ? (
            <span className={styles.error}>
              The code you entered is invalid. Please try again
            </span>
          ) : (
            ""
          )}
          <button type="submit" disabled={isDiabled}>
            Next
          </button>
        </form>
      </div>
    </div>
  );
};

export default EnterEmailCode;
